.tab {
  display: flex;
  gap: 4px;
  margin-bottom: 16px;
}

.tabItem {
  padding: 0px 12px;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.04em;
  text-align: center;
  color: #ffffff99;
}

.tabItem.active {
  color: #fffffff2;
}

.tabItem.active::after {
  content: "";
  display: block;
  margin-top: 4px;
  width: 100%;
  height: 2px;
  background: linear-gradient(
    270deg,
    rgba(43, 108, 94, 0) 0%,
    #5dc4ae 25%,
    #ffffff 50%,
    #5dc4ae 75%,
    rgba(43, 108, 94, 0) 100%
  );
}

.swiperWrapper {
  position: relative;
}

.expand {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: url("../../assets/images/common/s-arrow.svg") no-repeat;
  background-size: cover;
  width: 14px;
  height: 15px;
  cursor: pointer;
  z-index: 100;

  &.left {
    left: 0;
  }

  &.right {
    right: 0;
    transform: translateY(-50%) rotate(180deg);
  }
}