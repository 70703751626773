.notice {
  position: fixed;
  top: 86px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  display: flex;
  align-items: center;
  padding: 26px 26px 9px 84px;
  width: 798px;
  height: 132px;
  background-image: url("../../assets/images/notice/bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.icon {
  position: absolute;
  top: calc(50% + 42px);
  left: 6px;
  transform: translateY(calc(-50% - 9px));
  width: 120px;
  height: 118px;
  margin: -18.5px;
}

.title {
  font-size: 16px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.95);
}

.content {
  font-size: 14px;
  line-height: 21px;
  color: rgba(255, 255, 255, 0.76);
}
