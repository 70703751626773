
.tabButtons {
    position: absolute;
    left: 0;
    top: 36px;
    transform: translate3d(-100%, 0, 0);

    display: flex;
    flex-direction: column;
    gap: 16px;
}

