.subTitleWithIcon {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 16px;

  &.reverse {
    flex-direction: row-reverse;
  }
}

.title {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.95),
      rgba(255, 255, 255, 0.95)
    ),
    linear-gradient(180deg, #ffffff 0%, #b8fff0 86.59%);
  -webkit-background-clip: text !important;
  background-clip: text !important;
  -webkit-text-fill-color: transparent;
  color: transparent;
}

.icon {
  width: 6px;
  height: 6px;
  background: rgba(134, 243, 219, 1);
  transform: rotate(45deg);
}
