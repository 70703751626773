.progressBar {
    display: flex;
    position: relative;
    border-radius: 16px;
    background-color: rgba(49, 85, 77, 0.42);
    height: 8px;
}

.progress {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    border-radius: 16px;
    background: linear-gradient(270deg, rgba(96, 244, 212, 0.8) 0%, rgba(96, 244, 212, 0) 100%);
}


