.imageButton {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  width: 100%;
  height: 100%;
  background-color: transparent;
  background-size: 100%;
  border: none;
  cursor: pointer;
  color: #fffffff5;
  font-size: 14px;
  line-height: 18px;

  &:hover {
    opacity: 0.9;
  }
}

:global(.button-1) {
  position: relative;
  background: url("../../assets/images/modals/choice-bg.svg") no-repeat;
  width: 200px;
  height: 46px;

  &.active {
    background: url("../../assets/images/modals/choice-active-bg.svg") no-repeat;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 30px;
      width: 74px;
      height: 6px;
      background: linear-gradient(
        90deg,
        rgba(96, 244, 212, 0) 0%,
        rgba(96, 244, 212, 0.64) 30%,
        rgba(56, 142, 123, 0.128) 100%
      );
    }
  }
}

:global(.button-2),
:global(.button-3) {
  position: relative;
  background: url("../../assets/images/modals/choice-bg-2.svg") no-repeat;
  width: 200px;
  height: 46px;

  &.active {
    background: url("../../assets/images/modals/choice-active-bg-2.svg")
      no-repeat;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 30px;
      width: 74px;
      height: 6px;
      background: linear-gradient(
        90deg,
        rgba(96, 244, 212, 0) 0%,
        rgba(96, 244, 212, 0.64) 30%,
        rgba(56, 142, 123, 0.128) 100%
      );
    }
  }
}

:global(.tab-1),
:global(.tab-2) {
  background: linear-gradient(
    108.28deg,
    rgba(23, 51, 45, 0.86) 0%,
    rgba(23, 51, 45, 0.6) 51.38%,
    rgba(23, 51, 45, 0.86) 100%
  );
  background-image: url("../../assets/images/modals/tab-border.svg");
  background-repeat: no-repeat;
  width: 115px;
  height: 48px;
  border-radius: 8px 0px 0px 8px;

  &.active {
    background: linear-gradient(180deg, #0a261f 0%, #4be0c0 100%);
  }
}

:global(.close) {
  background: url("../../assets/images/modals/modal-button-close.svg") no-repeat;
  width: 36px;
  height: 36px;
}
