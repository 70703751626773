.warning {
  display: flex;
  margin-bottom: 10px;
  padding: 10px 16px 10px 16px;
  border-radius: 12px 0px 0px 0px;
  background: url('../../assets/images/common/warning-bg.png') no-repeat;
  background-size: cover;

  .icon {
    width: 32px;
    height: 32px;
    margin-right: 10px;
  }

  .content {
    font-size: 14px;
    line-height: 17px;
    background: rgba(253, 207, 213, 1);
  }

  .time {
    font-size: 12px;
    line-height: 18px;
  }
}

.forecastTitle {
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 10px;
  letter-spacing: 0.04em;
  background: linear-gradient(180deg, #ffffff 0%, #b8fff0 86.59%);
}

.forecastItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  line-height: 12px;
  color: rgba(255, 255, 255, 0.76);
  padding: 4px 12px 4px 12px;
  border-radius: 12px 0px 0px 0px;
  margin-bottom: 8px;
  background: linear-gradient(
    270deg,
    rgba(84, 144, 131, 0) 0%,
    rgba(84, 144, 131, 0.6) 100%
  );
}

.brief {
  display: flex;
  align-items: center;
  color: rgba(255, 255, 255, 0.95);

  .icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
}

.temperature {
  font-size: 20px;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.95);
}
