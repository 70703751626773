.agriculturalProductTraceability {
    display: flex;
    flex-direction: column;
    color: white;
    gap: 8px;
    font-size: 12px;
    line-height: 14.4px;

    .searchInfo {
        display: flex;
        align-items: center;
        gap: 16px;
        margin-top: 8px;

        .detail {
            display: flex;
            flex-direction: column;
            gap: 8px;
        }

        img {
            border: 1px solid rgba(54, 240, 151, 0.2);
        }

        label {
            color: rgba(255, 255, 255, .76);
        }

        label + span {
            color: rgba(255, 255, 255, .95);
        }
    }
}
