.switch {
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 24px;
  color: #fffffff2;
  cursor: pointer;
  user-select: none;

  .btn {
    background-image: url(../../assets/images/common/off.svg);
    background-repeat: no-repeat;
    background-size: cover;
    width: 50px;
    height: 22px;
    margin-left: 4px;

    &.active {
      background-image: url(../../assets/images/common/on.svg);
    }
  }
}
