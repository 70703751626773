.inputSearch {
    position: relative;
    display: flex;
    align-items: center;
    width: 172px;
    gap: 8px;
    background: linear-gradient(180deg, #0A261F 0%, #216456 100%);
    border-radius: 0px 8px 0px 8px;
    border: 1px solid rgba(54, 240, 151, 0.2) !important;

    &:after {
        content: '';
        width: 17px;
        height: 17px;
        z-index: 1;
        background: url('../../assets/images/common/search-lg.svg') no-repeat;
        background-size: 100%;
        margin-right: 8px;
    }
}

.inputSearch input {
    padding: 6px 8px;
    font-size: 12px;
    outline: none;
    color: white;
    background: transparent;
    border: none;
    width: 100%;

    &::placeholder {
        color: rgba(255, 255, 255, .8);
    }
}
