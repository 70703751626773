.head {
  width: 100%;
  height: calc(213 / 1920 * 100vw);
  font-size: 32px;
  line-height: 32px;
  letter-spacing: 0.24em;
  text-align: center;
  background-image: url("../../assets/images/guide/header-bg.png");
  background-size: 100%;
  background-repeat: no-repeat;
}
