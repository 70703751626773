.gradientButton {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px 6px 0px 6px;
  font-size: 14px;
  line-height: 14px;
  position: relative;
  background-color: rgba(10, 38, 32, 0.76);
  border-radius: 0px 6px 0px 6px;
  background-clip: padding-box;

  &.primary {
    background: linear-gradient(180deg, #0A261F 0%, #216456 100%);
    border: none;

    .gradientButtonContent {
      background: linear-gradient(
        180deg,
        #ffffff 13.54%,
        #6ed0bb 51.04%,
        #ffffff 84.9%
      );
    }
    .borderBg {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      z-index: -1;
      margin: -1px;
      border-radius: inherit;
      background: linear-gradient(45deg, #6ED0BB -0.14%, #294E46 24.36%, #294E46 78.36%, #6ED0BB 99.86%);
    }
  }

  &.warning {
    background: linear-gradient(180deg, #26200a 0%, #5e5222 100%);
    border: 1px solid rgba(147, 128, 54, 1);

    .gradientButtonContent {
      background: linear-gradient(
        180deg,
        #ffffff 13.54%,
        #ffea98 51.04%,
        #ffffff 84.9%
      );
    }
  }
}
