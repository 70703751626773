.aquacultureSituation {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.item {
  display: flex;
  align-items: center;
  width: 174px;
  height: 51px;
  padding: 8px 12px 8px 12px;
  border-radius: 12px 0px 0px 0px;
  border: none;
  color: rgba(255, 255, 255, 0.76);
  background: url('../../assets/images/common/primary-bg.png') no-repeat;
  background-size: cover;
}

.item:hover {
  background: url
}

.icon {
  width: 20px;
  height: 20px;
  margin-right: 12px;
}

.title {
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 4px;
}

.data {
  font-size: 12px;
  line-height: 12px;

  span {
    font-size: 16px;
    line-height: 20px;
  }
}
