.imgSwiper {
  background: url(../../assets/images/intro/img-border.png) no-repeat;
  background-size: 100% 100%;
  width: 100%;
  padding: 8px;
}

.pagination {
  display: flex;
  justify-content: center;
  gap: 5px;
  margin-top: 4px;
}

.paginationItem {
  width: 16px;
  height: 5px;
  border-radius: 1px 0px 0px 0px;
  background: rgba(129, 221, 202, 0.17);
  transform: skew(-40deg);
}

.paginationItem.active {
  background: rgba(81, 255, 217, 1);
  box-shadow: 0px 0px 1.5px 0px rgba(249, 255, 254, 1) inset;
  box-shadow: 0px 0px 14.5px 0px rgba(234, 255, 250, 0.2);
}
