.horizontalDataTable {
    width: 360px;
    color: white;
    font-size: 14px;
}

.horizontalDataTable th {
    width: 48px;
    text-align: right;
}

.horizontalDataTable th,
.horizontalDataTable td {
    padding: 4px;
}

.value {
    text-align: right;
    width: 80px;
}

.unit {
    color: rgba(255, 255, 255, 0.6);
    font-size: 12px;
    margin-left: 4px;
}
