.guidePage {
  position: absolute;
  width: 100vw;
  height: calc(100vw * 0.5625);

  top: 50%;
  transform: translate3d(0, -50%, 0);

  background-image: url("../assets/images/guide/bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  .mask {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
  }

  .content {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 1;
  }

  .navBar {
    position: absolute;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    bottom: 126px;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
  }

  .mainEntryWrapper {
    position: relative;
    width: 660px;
    height: 660px;
    z-index: 3;

    .mainEntryBubbles {
      width: 400px;
      height: 400px;
      left: 50%;
      top: 300px;
      transform: translate(-50%, -50%);
      z-index: 10;
    }

    .mainEntryRotating {
      position: absolute;
      width: 660px;
      height: 660px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      background: url("../assets/images/guide/rotate.png") no-repeat;
      background-size: 100%;
      animation: rotating 10s infinite linear;
    }
  }

  .start {
    position: absolute;
    top: -15px;
    left: 50%;
    transform: translateX(-50%);
    width: 712px;
    z-index: 2;
    cursor: pointer;
    transition: opacity 0.2s ease;

    &:hover {
      opacity: 0.95;
    }
  }

  .navBarBg,
  .navItems {
    width: 1343px;
    padding: 0 48px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  
  .navBarBg {
    height: 358px;
    margin-top: -252px;
    background-image: url("../assets/images/guide/nav-bar-bg.png");
    z-index: 2;
  }

  .navItems {
    position: absolute;
    bottom: 96px;
    height: 165px;
    display: flex;
    justify-content: space-evenly;
    z-index: 3;
  }
}

@keyframes rotating {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
