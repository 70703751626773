.checkboxWrapper {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  line-height: 14.4px;

  .label {
    display: flex;
    align-items: center;
    flex-shrink: 0;

    .icon {
      width: 12px;
      height: 12px;
      margin-right: 8px;
    }
  }

  .checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    border-radius: 2px;
    border: 1px solid #b6fbec;
    background: linear-gradient(180deg, #ffffff 0%, #b8fff0 86.59%);

    .checkmark {
      width: 11px;
      height: 8px;
      background: url("../../assets/images/checkbox/checked.svg") no-repeat;
      background-size: cover;
    }
  }
}

.checkboxListWrapper {
  position: fixed;
  left: 440px;
  bottom: 120px;
  z-index: 10;
  width: 132px;
  padding: 10px;
  background: linear-gradient(
    108.28deg,
    rgba(23, 51, 45, 0.86) 0%,
    rgba(23, 51, 45, 0.6) 51.38%,
    rgba(23, 51, 45, 0.86) 100%
  );
  border: 1px solid #1c5448;
  border-top-right-radius: 12px;
}

.checkboxList {
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  z-index: 1;
}

.border {
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
}

.topBorder {
  top: -1px;
  right: -1px;
  width: 40px;
  height: 60px;
  background-image: url("../../assets/images/checkbox/rt.svg");
}

.bottomBorder {
  left: -1px;
  bottom: -1px;
  width: 32px;
  height: 32px;
  background-image: url("../../assets/images/checkbox/lb.svg");
}
