.historyCulture {
  .coverImg {
    width: 100%;
    border: 1px solid #70c5a8;
    border-radius: 0px 16px 0px 16px;
  }

  .text {
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: #ffffffc2;
    margin-top: 4px;
  }

  .imgWrapper {
    position: relative;
  }

  .play, .fullscreen {
    position: absolute;
  }

  .play {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .fullscreen {
    bottom: 14px;
    right: 14px;
  }
}
