.bubbleAnimation {
    position: absolute;
    inset: 0;
    overflow: hidden; /* 隐藏溢出的部分 */
    border-radius: 50%;
}

.bubble {
    position: absolute;
    bottom: -100px;
    width: 8px;
    height: 8px;
    background-color: rgba(20, 239, 112, 1);
    border-radius: 50%;
    animation: float 6s infinite;
    animation-timing-function: linear;
    opacity: 0;
    transform: scale3d(1, 1, 1);
}

@keyframes float {
    0% {
        transform: scale3d(0.5, 0.5, 0.5) translateY(0);
        opacity: 0;
    }
    50% {
        transform: translateY(-300px);
        opacity: 1;
    }
    100% {
        transform: scale3d(1, 1, 1) translateY(-600px);
        opacity: 0;
    }
}

/* 随机化泡泡的大小、位置和动画延迟 */
.bubble:nth-child(1) {
    width: 30px;
    height: 30px;
    left: 10%;
    animation-delay: 0s;
    animation-duration: 7s;
}

.bubble:nth-child(2) {
    width: 50px;
    height: 50px;
    left: 25%;
    animation-delay: 2s;
    animation-duration: 12s;
}

.bubble:nth-child(3) {
    width: 70px;
    height: 70px;
    left: 40%;
    animation-delay: 4s;
    animation-duration: 9s;
}

.bubble:nth-child(4) {
    width: 40px;
    height: 40px;
    left: 55%;
    animation-delay: 1s;
    animation-duration: 11s;
}

.bubble:nth-child(5) {
    width: 60px;
    height: 60px;
    left: 70%;
    animation-delay: 3s;
    animation-duration: 8s;
}
