.cornerDecorationImg {
  position: relative;
  padding: 4px;
  flex-shrink: 0;
  background: rgba(23, 51, 45, 0.32);
  border: 1px solid rgba(84, 144, 131, 0.32);
}

.decoration {
  position: absolute;
  width: 8px;
  height: 8px;
  background: url("../../assets/images/video-monitoring/stroke.svg") no-repeat
    100% 100%;
}

.decorationTopLeft {
  top: 0;
  left: 0;
}
.decorationTopRight {
  top: 0;
  right: 0;
  transform: rotate(90deg);
}
.decorationBottomLeft {
  bottom: 0;
  left: 0;
  transform: rotate(-90deg);
}
.decorationBottomRight {
  bottom: 0;
  right: 0;
  transform: rotate(180deg);
}
