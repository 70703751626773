.touristGenderOverview {
    width: 360px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 16px;
}

.ratioInfo {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 28px;
    width: 198px;
    margin: 0 16px;

    .genderMale,
    .genderFemale {
        position: absolute;
        inset: 0;
    }

    .genderMale {
        background: url('../../assets/images/chart/gender_male.svg') 0 no-repeat;
        background-size: auto 100%;
        right: auto;
    }

    .genderFemale {
        background: url('../../assets/images/chart/gender_female.svg') 100% no-repeat;
        background-size: auto 100%;
        left: auto;
    }
}

.genderInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    span {
        background: linear-gradient(180deg, #FFFFFF 0%, #78A0EF 100%);
        -webkit-background-clip: text;
        color: transparent;
    }
}

.genderInfo:last-child {
    align-items: flex-start;

    span {
        background: linear-gradient(180deg, #FFFFFF 0%, #F4816F 100%);
        -webkit-background-clip: text;
        color: transparent;
    }
}
