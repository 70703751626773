.tab {
  display: flex;
  gap: 4px;
  margin-bottom: 16px;
}

.tabItem {
  padding: 0px 12px;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.04em;
  text-align: center;
  color: #fffffff2;
}

.tabItem.active::after {
  content: "";
  display: block;
  margin-top: 4px;
  width: 100%;
  height: 2px;
  background: linear-gradient(
    270deg,
    rgba(43, 108, 94, 0) 0%,
    #5dc4ae 25%,
    #ffffff 50%,
    #5dc4ae 75%,
    rgba(43, 108, 94, 0) 100%
  );
}

.title {
  margin: 8px 0;
  font-size: 14px;
  line-height: 14px;
  background: linear-gradient(180deg, #ffffff 0%, #b8fff0 86.59%);
}

.content {
  font-size: 14px;
  line-height: 22px;
  color: #ffffffc2;
}

.textWrapper {
  text-indent: 1em;
  text-align: justify;
  overflow: scroll;
  margin-bottom: 16px;
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow-x: none;
  overflow-y: none;

  .text {
    transition: all 0.1s ease-in-out;
  }
}
