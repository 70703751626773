.gongMiContent {
}

.stepButtons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
}

.fieldProductionProcess {
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 1160px;
    height: 766px;

    .u1 {
        position: absolute;
        top: 198.09px;
        left: 271.16px;
        background: url('../../../assets/images/modals/u1.svg') no-repeat;
        background-size: 100%;
        width: 626px;
        height: 443.67px;
    }

    .gongMiContentStep {
        position: absolute;
        width: 73px;
        height: 98px;
        background-size: 100% !important;
        opacity: 0;
        animation: linear 500ms fadeIn forwards;
    }

    .gongMiContent1Step1 {
        background: url('../../../assets/images/modals/gongmi-content-1/content-1-step-1.png') no-repeat;
        top: 322.39px;
        left: 279.13px;
    }

    .gongMiContent1Step2 {
        background: url('../../../assets/images/modals/gongmi-content-1/content-1-step-2.png') no-repeat;
        top: 247.91px;
        left: 419.85px;
    }

    .gongMiContent1Step3 {
        background: url('../../../assets/images/modals/gongmi-content-1/content-1-step-3.png') no-repeat;
        top: 169.11px;
        left: 554.58px;
    }

    .gongMiContent1Step4 {
        background: url('../../../assets/images/modals/gongmi-content-1/content-1-step-4.png') no-repeat;
        top: 157.11px;
        left: 720.58px;
    }

    .gongMiContent1Step5 {
        background: url('../../../assets/images/modals/gongmi-content-1/content-1-step-5.png') no-repeat;
        top: 277.11px;
        left: 807.58px;
    }

    .gongMiContent1Step6 {
        background: url('../../../assets/images/modals/gongmi-content-1/content-1-step-6.png') no-repeat;
        top: 360.11px;
        left: 665.58px;
    }

    .gongMiContent1Step7 {
        background: url('../../../assets/images/modals/gongmi-content-1/content-1-step-7.png') no-repeat;
        top: 435.11px;
        left: 535.58px;
    }

    .gongMiContent1Step8 {
        background: url('../../../assets/images/modals/gongmi-content-1/content-1-step-8.png') no-repeat 100%;
        top: 457px;
        left: 380px;
        width: 134px;
        height: 165px;
    }

}

.productionProcess {
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 1160px;
    height: 766px;

    .u2 {
        position: absolute;
        background: url('../../../assets/images/modals/u2.png') no-repeat;
        background-size: 100%;
        width: 982.16px;
        height: 746.55px;
        top: 56px;
        left: 92.98px;
    }

    .gongMiContentStep {
        position: absolute;
        width: 72px;
        height: 98px;
        background-size: 100% !important;
        opacity: 0;
        animation: linear 500ms fadeIn forwards;
    }

    .gongMiContent2Step1 {
        background: url('../../../assets/images/modals/gongmi-content-2/content-2-step-1.png') no-repeat;
        top: 160.75px;
        left: 100.91px;
    }

    .gongMiContent2Step2 {
        background: url('../../../assets/images/modals/gongmi-content-2/content-2-step-2.png') no-repeat;
        top: 88.27px;
        left: 219.71px;
    }

    .gongMiContent2Step3 {
        background: url('../../../assets/images/modals/gongmi-content-2/content-2-step-3.png') no-repeat;
        top: 18.54px;
        left: 341.1px;
    }

    .gongMiContent2Step4 {
        background: url('../../../assets/images/modals/gongmi-content-2/content-2-step-4.png') no-repeat;
        top: 6.54px;
        left: 484.9px;
    }

    .gongMiContent2Step5 {
        background: url('../../../assets/images/modals/gongmi-content-2/content-2-step-5.png') no-repeat;
        top: 88.54px;
        left: 582.9px;
    }

    .gongMiContent2Step6 {
        background: url('../../../assets/images/modals/gongmi-content-2/content-2-step-6.png') no-repeat;
        top: 164.54px;
        left: 489.85px;
    }

    .gongMiContent2Step7 {
        background: url('../../../assets/images/modals/gongmi-content-2/content-2-step-7.png') no-repeat;
        top: 237.54px;
        left: 376.91px;
    }

    .gongMiContent2Step8 {
        background: url('../../../assets/images/modals/gongmi-content-2/content-2-step-8.png') no-repeat;
        top: 307.54px;
        left: 259.91px;
    }

    .gongMiContent2Step9 {
        background: url('../../../assets/images/modals/gongmi-content-2/content-2-step-9.png') no-repeat;
        top: 390.54px;
        left: 155.91px;
    }

    .gongMiContent2Step10 {
        background: url('../../../assets/images/modals/gongmi-content-2/content-2-step-10.png') no-repeat;
        top: 477.54px;
        left: 257.91px;
    }

    .gongMiContent2Step11 {
        background: url('../../../assets/images/modals/gongmi-content-2/content-2-step-11.png') no-repeat;
        top: 469.54px;
        left: 376.9px;
    }

    .gongMiContent2Step12 {
        background: url('../../../assets/images/modals/gongmi-content-2/content-2-step-12.png') no-repeat;
        top: 395.54px;
        left: 489.9px;
    }

    .gongMiContent2Step13 {
        background: url('../../../assets/images/modals/gongmi-content-2/content-2-step-13.png') no-repeat;
        top: 337.54px;
        left: 593.9px;
    }

    .gongMiContent2Step14 {
        background: url('../../../assets/images/modals/gongmi-content-2/content-2-step-14.png') no-repeat;
        top: 269.54px;
        left: 702.81px;
    }

    .gongMiContent2Step15 {
        background: url('../../../assets/images/modals/gongmi-content-2/content-2-step-15.png') no-repeat;
        top: 213.54px;
        left: 807.9px;
    }

    .gongMiContent2Step16 {
        background: url('../../../assets/images/modals/gongmi-content-2/content-2-step-16.png') no-repeat;
        top: 254.54px;
        left: 919.9px;
    }

    .gongMiContent2Step17 {
        background: url('../../../assets/images/modals/gongmi-content-2/content-2-step-17.png') no-repeat;
        top: 375.54px;
        left: 983.91px;
    }

    .gongMiContent2Step18 {
        background: url('../../../assets/images/modals/gongmi-content-2/content-2-step-18.png') no-repeat;
        top: 443.54px;
        left: 872.9px;
    }

    .gongMiContent2Step19 {
        background: url('../../../assets/images/modals/gongmi-content-2/content-2-step-19.png') no-repeat;
        top: 509.54px;
        left: 760.9px;
    }

    .gongMiContent2Step20 {
        background: url('../../../assets/images/modals/gongmi-content-2/content-2-step-20.png') no-repeat;
        top: 569.54px;
        left: 648.9px;
    }

    .gongMiContent2Step21 {
        background: url('../../../assets/images/modals/gongmi-content-2/content-2-step-21.png') no-repeat;
        top: 620px;
        left: 510px;
        width: 134px;
        height: 165px;
    }
}

.gongmiProcessingContent {
    width: 1160px;
    height: 478px;
    background: url('../../../assets/images/modals/gongmi-content-3/content-3.png') no-repeat;
    background-size: 100%;
}

@keyframes fadeIn {
    0% {
        transform: translate3d(0, 20%, 0);
        opacity: .3;
    }
    100% {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}
