.layout {
  position: absolute;
  width: 100vw;
  height: calc(100vw * 0.5625);
  top: 50%;
  transform: translate3d(0, -50%, 0);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  .navBar {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    transform-origin: left bottom;
    width: 100%;
    z-index: 10;
  }
}

.bottomPanel {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 16px;
  height: 439px;
  background: url("../../assets/images/common/bottom-menu-bg.png") no-repeat;
  background-size: cover;
  background-position: center;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 40px 12px;
  font-size: 14px;
  line-height: 14px;
}

.date,
.weather {
  display: flex;
  gap: 16px;
}

.weather {
  gap: 32px;

  > div {
    display: flex;
    align-items: center;
  }

  .icon {
    margin-right: 4px;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .weatherIcon {
    width: 20px;
    height: 20px;
    background-image: url("../../assets/images/common/sun-icon.svg");
  }

  .hideIcon {
    width: 18px;
    height: 18px;
    background-image: url("../../assets/images/common/hide-icon.svg");
  }

  > div + div {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      left: -16px;
      top: 50%;
      transform: translateY(-50%);
      width: 1px;
      height: 10px;
      background: rgba(190, 251, 238, 1);
    }
  }
}

.panel {
  width: 428px;
  padding-top: 56px;
  background: url("../../assets/images/common/panel-bg.png") no-repeat;
  background-size: contain;
  position: absolute;
  z-index: 20;

  &.left {
    left: 0;
    padding-left: 68px;
    transform-origin: left top;
  }

  &.right {
    right: 0;
    padding-right: 68px;
    background-image: url("../../assets/images/common/panel-right-bg.png");
    transform-origin: right top;
  }
}

.expand {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  background: url("../../assets/images/common/expand-icon.svg") no-repeat;
  background-size: cover;
  width: 28px;
  height: 28px;
  cursor: pointer;

  &.left {

    &.hide {
      transform: translateY(-50%) rotate(180deg);
    }
  }

  &.right {
    transform: translateY(-50%) rotate(180deg);

    &.hide {
      transform: translateY(-50%) rotate(0);
    }
  }
}
