.rosterTable {
  width: 100%;
  border-top-left-radius: 12px;
  overflow: hidden;
  border-collapse: collapse;
  font-size: 12px;
  line-height: 18px;
  color: #fffffff2;
}

.thItem {
  background: linear-gradient(
    270deg,
    rgba(104, 204, 182, 0.144) 0%,
    rgba(104, 204, 182, 0.48) 100%
  );
}

.th {
  padding: 6px 12px;
  text-align: left;

  &:first-child {
    text-align: center;
  }
}

.tbody {
  display: block;
  height: calc(30px * 8);
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow-x: none;
  overflow-y: none;
}

.thItem, .tbItem {
  width: 100%;
  display: table;
  table-layout: fixed;
}

.tbItem:nth-child(even) {
  background: linear-gradient(
    90deg,
    rgba(31, 72, 63, 0.64) 0%,
    rgba(31, 72, 63, 0.256) 100%
  );
}

.tbItem > td:first-child {
  text-align: center;
}

.td {
  padding: 6px 12px;
}
