.hotPlate {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  padding: 10px 16px 10px 16px;
  background: url('../../assets/images/common/primary-sm-bg.png') no-repeat;
  background-size: cover;

  .main {
    display: flex;
    align-items: center;
    color: rgba(255, 255, 255, 0.95);
    font-size: 12px;
    line-height: 18px;
  }
}

.cover {
  width: 44px;
  height: 44px;
  margin-right: 10px;
}

.content {
  .title {
    font-size: 16px;
    line-height: 24px;
  }
}

.detailButton {
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.04em;
  cursor: pointer;
  background: linear-gradient(
    180deg,
    #ffffff 13.54%,
    #6ed090 51.04%,
    #ffffff 84.9%
  );

  .arrowIcon {
    width: 12px;
    height: 12px;
    margin-left: 4px;
    background: url('../../assets/images/common/gradient-arrow.svg') no-repeat 100% 100%;
  }
}
