.detailContent {
    display: flex;
    align-items: center;
    height: 100%;
    gap: 20px;

    > div {
        flex: 1;
    }
}

.contentBlock {
    display: flex;
    gap: 4px;
}

.contentBlockTitle {
    font-size: 14px;
    line-height: 18px;
}

.contentBlockContent {
    font-size: 12px;
    line-height: 14.4px;
}
