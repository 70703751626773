:root {
  --font: "DingTalk JinBuTi", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  --green-text-gradient: linear-gradient(180deg, #ffffff 0%, #b8fff0 86.59%);
}

@font-face {
  font-family: "DingTalk JinBuTi";
  src: url("./assets/fonts/DingTalkJinBuTi.ttf");
}

@font-face {
  font-family: "DingTalk Sans";
  src: url("./assets/fonts/DingTalkSans.ttf");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  user-select: none;
}

body {
  margin: 0;
  font-weight: 400;
  font-family: var(--font);
  background: #0a261f;
  text-align: justify;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

input {
  font-family: var(--font);
}

img {
  vertical-align: middle;
  max-width: 100%;
}

.teagarden-text-gradient {
  -webkit-background-clip: text !important;
  background-clip: text !important;
  -webkit-text-fill-color: transparent;
  color: transparent;
}

.teagarden-text-gradient.green {
  background-image: var(--green-text-gradient);
}

.teagarden-layout-item {
  margin-bottom: 24px;
}

.teagarden-layout-subitem {
  margin-bottom: 16px;
}

.ReactModal__Overlay {
  background: rgba(3, 15, 13, 0.8);
}

.ReactModal__Content {
  position: absolute;
  width: 1200px;
  top: 50% !important;
  left: 50% !important;
  bottom: auto !important;
  right: auto !important;
  transform: translate3d(-50%, -50%, 0);
  height: auto;
  margin: 0 auto;
  background: rgba(10, 38, 32, 0.8) !important;
  border: 1px solid rgba(54, 240, 151, 0.2) !important;
  color: white;
  border-radius: 0 16px 0 16px !important;
  padding: 20px !important;
  overflow: unset !important;

  &:focus-visible {
    outline: none !important;
  }
}

.teagarden-button-group {
  position: fixed;
  width: 128px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  right: 448px;
  top: calc(100% - 210px);
  z-index: 10;
}

.teagarden-pointer-item {
  cursor: pointer;
}

.teagarden-overflow-scroll {
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow-x: none;
  overflow-y: none;
}

.teagarden-text-overflow {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
