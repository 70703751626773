.dropdowWrapper {
  position: relative;
  margin-bottom: 8px;

  .border {
    position: absolute;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 1;
  }

  .topBorder {
    top: -2px;
    right: -2px;
    width: 36px;
    height: 20px;
    background-image: url("../../assets/images/common/btn-rt.svg");
  }

  .bottomBorder {
    left: -2px;
    bottom: -2px;
    width: 24px;
    height: 24px;
    background-image: url("../../assets/images/common/btn-lb.svg");
  }
}

.dropdown {
  position: relative;
  font-size: 16px;
  line-height: 24px;
  width: 100%;
  padding: 6px 12px 6px 12px;
  background: linear-gradient(
    108.28deg,
    rgba(23, 51, 45, 0.86) 0%,
    rgba(23, 51, 45, 0.6) 51.38%,
    rgba(23, 51, 45, 0.86) 100%
  );
  border: 1px solid #1c5448;
  border-top-right-radius: 12px;
  z-index: 2;

  &.active {
    background: linear-gradient(180deg, #0a261f 0%, #2ba389 100%);
    .button {
      .label {
        color: #fffffff5;
      }
    }
  }

  .button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4px;

    .icon {
      background-repeat: no-repeat;
      background-size: 100% 100%;
      width: 16px;
      height: 16px;
    }

    .arrowIcon {
      background-repeat: no-repeat;
      background-size: cover;
      margin-left: 4px;
      background: url("../../assets/images/common/arrow-down.svg") no-repeat
        100% 100%;

      &.active {
        transform: rotate(180deg);
      }
    }

    .label {
      flex-shrink: 0;
      color: #ffffff99;
    }
  }

  .content {
    margin-top: 8px;
  }

  &.gradient {
    font-size: 14px;
    line-height: 16.8px;
    border-radius: 0px 6px 0px 6px;
    padding: 4px 8px 4px 8px;
    background: linear-gradient(180deg, #0a261f 0%, #216456 100%);
    background-clip: padding-box;
    border: none;

    .borderBg {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      z-index: -1;
      margin: -2px;
      border-radius: inherit;
      background: linear-gradient(
        20deg,
        #6ed0bb -0.14%,
        #294e46 24.36%,
        #294e46 78.36%,
        #6ed0bb 99.86%
      );
    }
    .label {
      color: transparent;
      background: #ffffff99;
      background: linear-gradient(
        180deg,
        #ffffff 13.54%,
        #6ed0bb 51.04%,
        #ffffff 84.9%
      );
    }
  }
}
