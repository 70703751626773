.routeOption {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #fffffff2;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;

  .icon {
    width: 12px;
    height: 12px;
    background: url("../assets/images/common/route-default-icon.svg");
    background-repeat: no-repeat;
    background-size: cover;

    &.active {
      background-image: url("../assets/images/common/route-icon.svg");
    }
  }

  & + .routeOption {
    margin-top: 8px;
  }
}
