.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.capture {
  display: flex;
  color: rgba(255, 255, 255, 0.95);
  font-size: 12px;
  line-height: 14.4px;

  h3 {
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.04em;
    background: linear-gradient(180deg, #ffffff 0%, #b8fff0 86.59%);
    margin-bottom: 4px;
  }

  .label {
    flex-shrink: 0;
    width: 60px;
    line-height: 12px;
    color: rgba(255, 255, 255, 0.76);
  }
}

.content {
  padding-top: 7.5px;
}

.contentItem {
  display: flex;
  margin-bottom: 4px;
}

.monitoringContent {
  background: linear-gradient(
    270deg,
    rgba(46, 147, 124, 0) 0%,
    rgba(46, 147, 124, 0.5) 100%
  );
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.placements {
  width: 100%;
  display: flex;
  gap: 8px;
}

.dropdown {
  width: 124px;
}
