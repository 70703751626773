.contentModal {
  width: 952px !important;
  height: 346px !important;
}

.historyCultureModal {
  display: flex;
  justify-content: space-between;
  gap: 16px;

  .cultureItem {
    height: 263px;
    font-size: 14px;
    line-height: 22px;
    color: #ffffffc2;
    text-indent: 1em;
    overflow: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    overflow-x: none;
    overflow-y: none;
  }

  .video {
    width: 544px;
    height: 306px;
    border: 1px solid #70c5a8;
    border-radius: 0 16px 0px 16px;
    flex-shrink: 0;
  }
}
