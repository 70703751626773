.titleWithIcon {
  display: flex;
  align-items: center;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 0.04em;
  margin-bottom: 16px;
}

.reverse {
  flex-direction: row-reverse;
  .icon {
    margin-right: 0;
    margin-left: 12px;
  }
}

.icon {
  width: 18px;
  height: 18px;
  margin-right: 12px;
}

.text {
  background: linear-gradient(180deg, #ffffff 0%, #b8fff0 86.59%);
  -webkit-background-clip: text !important;
  background-clip: text !important;
  -webkit-text-fill-color: transparent;
  color: transparent;
}
