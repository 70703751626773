.contentModal {
    width: 780px !important;
    height: 348px !important;
}

.contentWrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
}
