.overlay {
  position: fixed;
  inset: 0px;
  background: transparent !important;

  &.active {
    background: rgba(3, 15, 13, 0.8) !important;
  }
}

.border {
  position: absolute;
  width: 48px;
  height: 48px;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../../../assets/images/modals/modal-border.svg");

  &.rect {
    width: 32px;
    height: 32px;
    background-image: url("../../../assets/images/modals/modal-border-rect.png");
  }

  &.left {
    top: -1px;
    left: -1px;
  }
  &.right {
    top: -1px;
    right: -1px;
  }
  &.bRight {
    bottom: -1px;
    right: -1px;
    transform: rotate(180deg);
  }
  &.bLeft {
    bottom: -1px;
    left: -1px;
    transform: rotate(180deg);
  }
}
