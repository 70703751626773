.navItem {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    opacity: 0.6;

    &:hover {
        .icon {
            transform: scale(1.1);
        }
    }

    .icon {
        transition: transform 0.2s ease;
        width: calc(332px / 2);
        height: calc(330px / 2);
    }

    .label {
        font-size: 24px;
        letter-spacing: 0.12em;
        background: linear-gradient(180deg, #ffffff 16.07%, #a5ffec 83.93%);
        -webkit-background-clip: text !important;
        background-clip: text !important;
        -webkit-text-fill-color: transparent;
        color: transparent;
    }

    &.column {
        .label {
            font-size: 16px;
            line-height: 16px;
            letter-spacing: 0.12em;
            margin-top: -30px;
        }
    }

    &.active {
        opacity: 1;
    }
}
