.gongTeaContent {
}

.stepButtons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
}

.fieldProductionProcess {
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 1160px;
    height: 766px;

    .u1 {
        position: absolute;
        top: 198.09px;
        left: 271.16px;
        background: url('../../../assets/images/modals/u1.svg') no-repeat;
        background-size: 100%;
        width: 626px;
        height: 443.67px;
    }

    .gongTeaContentStep {
        position: absolute;
        width: 73px;
        height: 98px;
        background-size: 100% !important;
        opacity: 0;
        animation: linear 500ms fadeIn forwards;
    }

    .gongTeaContent1Step1 {
        background: url('../../../assets/images/modals/gongtea-content-1/content-1-step-1.png') no-repeat;
        top: 322.39px;
        left: 279.13px;

        &:before {
            content: '';
            position: absolute;
            left: -250px;
            top: -100px;
            background: url('../../../assets/images/modals/gongtea-content-1/content-1-step-1-tip.png') no-repeat;
            background-size: 100%;
            width: 289px;
            height: 125px;
        }
    }

    .gongTeaContent1Step2 {
        background: url('../../../assets/images/modals/gongtea-content-1/content-1-step-2.png') no-repeat;
        top: 238.91px;
        left: 419.85px;

        &:before {
            content: '';
            position: absolute;
            left: -200px;
            top: -140px;
            background: url('../../../assets/images/modals/gongtea-content-1/content-1-step-2-tip.png') no-repeat;
            background-size: 100%;
            width: 289px;
            height: 143px;
        }
    }

    .gongTeaContent1Step3 {
        background: url('../../../assets/images/modals/gongtea-content-1/content-1-step-3.png') no-repeat;
        top: 160.11px;
        left: 554.58px;

        &:before {
            content: '';
            position: absolute;
            left: -35px;
            top: -130px;
            background: url('../../../assets/images/modals/gongtea-content-1/content-1-step-3-tip.png') no-repeat;
            background-size: 100%;
            width: 289px;
            height: 132px;
        }
    }

    .gongTeaContent1Step4 {
        background: url('../../../assets/images/modals/gongtea-content-1/content-1-step-4.png') no-repeat;
        top: 169.11px;
        left: 738.58px;

        &:before {
            content: '';
            position: absolute;
            left: 60px;
            top: -50px;
            background: url('../../../assets/images/modals/gongtea-content-1/content-1-step-4-tip.png') no-repeat;
            background-size: 100%;
            width: 334px;
            height: 103.5px;
        }
    }

    .gongTeaContent1Step5 {
        background: url('../../../assets/images/modals/gongtea-content-1/content-1-step-5.png') no-repeat;
        top: 327.94px;
        left: 718.92px;

        &:before {
            content: '';
            position: absolute;
            left: 60px;
            top: 50px;
            background: url('../../../assets/images/modals/gongtea-content-1/content-1-step-5-tip.png') no-repeat;
            background-size: 100%;
            width: 354px;
            height: 123px;
        }
    }

    .gongTeaContent1Step6 {
        background: url('../../../assets/images/modals/gongtea-content-1/content-1-step-6.png') no-repeat;
        top: 415.11px;
        left: 562.58px;

        &:before {
            content: '';
            position: absolute;
            left: 60px;
            top: 50px;
            background: url('../../../assets/images/modals/gongtea-content-1/content-1-step-6-tip.png') no-repeat;
            background-size: 100%;
            width: 309px;
            height: 142px;
        }
    }

    .gongTeaContent1Step7 {
        background: url('../../../assets/images/modals/gongtea-content-1/content-1-step-7.png') no-repeat;
        top: 457px;
        left: 380px;
        width: 134px;
        height: 165px;
    }
}

.productionProcess {
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 1160px;
    height: 766px;

    .u1 {
        position: absolute;
        top: 198.09px;
        left: 271.16px;
        background: url('../../../assets/images/modals/u1.svg') no-repeat;
        background-size: 100%;
        width: 626px;
        height: 443.67px;
    }

    .gongTeaContentStep {
        position: absolute;
        width: 72px;
        height: 98px;
        background-size: 100% !important;
        opacity: 0;
        animation: linear 500ms fadeIn forwards;
    }

    .gongTeaContent2Step1 {
        background: url('../../../assets/images/modals/gongtea-content-2/content-2-step-1.png') no-repeat;
        top: 322.39px;
        left: 279.13px;

        &:before {
            content: '';
            position: absolute;
            left: -250px;
            top: -90px;
            background: url('../../../assets/images/modals/gongtea-content-2/content-2-step-1-tip.png') no-repeat;
            background-size: 100%;
            width: 289px;
            height:108px
        }
    }

    .gongTeaContent2Step2 {
        background: url('../../../assets/images/modals/gongtea-content-2/content-2-step-2.png') no-repeat;
        top: 238.91px;
        left: 419.85px;

        &:before {
            content: '';
            position: absolute;
            left: -200px;
            top: -140px;
            background: url('../../../assets/images/modals/gongtea-content-2/content-2-step-2-tip.png') no-repeat;
            background-size: 100%;
            width: 289px;
            height: 143px;
        }
    }

    .gongTeaContent2Step3 {
        background: url('../../../assets/images/modals/gongtea-content-2/content-2-step-3.png') no-repeat;
        top: 160.11px;
        left: 554.58px;

        &:before {
            content: '';
            position: absolute;
            left: -35px;
            top: -130px;
            background: url('../../../assets/images/modals/gongtea-content-2/content-2-step-3-tip.png') no-repeat;
            background-size: 100%;
            width: 289px;
            height: 132px;
        }
    }

    .gongTeaContent2Step4 {
        background: url('../../../assets/images/modals/gongtea-content-2/content-2-step-4.png') no-repeat;
        top: 164.11px;
        left: 738.58px;

        &:before {
            content: '';
            position: absolute;
            left: 60px;
            top: -50px;
            background: url('../../../assets/images/modals/gongtea-content-2/content-2-step-4-tip.png') no-repeat;
            background-size: 100%;
            width: 334px;
            height: 103.5px;
        }
    }

    .gongTeaContent2Step5 {
        background: url('../../../assets/images/modals/gongtea-content-2/content-2-step-5.png') no-repeat;
        top: 337.94px;
        left: 718.92px;

        &:before {
            content: '';
            position: absolute;
            left: 60px;
            top: 50px;
            background: url('../../../assets/images/modals/gongtea-content-2/content-2-step-5-tip.png') no-repeat;
            background-size: 100%;
            width: 354px;
            height: 123px;
        }
    }

    .gongTeaContent2Step6 {
        background: url('../../../assets/images/modals/gongtea-content-2/content-2-step-6.png') no-repeat;
        top: 425.11px;
        left: 562.58px;

        &:before {
            content: '';
            position: absolute;
            left: 60px;
            top: 50px;
            background: url('../../../assets/images/modals/gongtea-content-2/content-2-step-6-tip.png') no-repeat;
            background-size: 100%;
            width: 309px;
            height: 142px;
        }
    }

    .gongTeaContent2Step7 {
        background: url('../../../assets/images/modals/gongtea-content-2/content-2-step-7.png') no-repeat;
        top: 477px;
        left: 380px;
        width: 134px;
        height: 165px;
    }
}

@keyframes fadeIn {
    0% {
        transform: translate3d(0, 20%, 0);
        opacity: .3;
    }
    100% {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}
