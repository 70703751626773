.svgIcon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;

  svg {
    width: 100%;
    height: 100%;
    vertical-align: middle;
  }
}